<template>
    <v-app>
        <v-navigation-drawer 
            v-model="drawer"
            app
            :mini-variant="!drawer"            
            :permanent="!$vuetify.breakpoint.mobile"
            :dark="darkMode"
            :color="color"
            bottom
        >
            <v-list-item 
                :background="color"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <v-img
                            style="max-width:150px; margin: auto;"
                            :src="img"
                            contain
                        />
                    </v-list-item-title>
                    <v-list-item-subtitle style="text-align: center;">
                        {{ $t('systemsetting-5') }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            
            <v-divider />
            
            <v-list
                dense
                nav
            >
                <v-list-item
                    v-if="locale === 'da'"
                    @click="linkToPNumberSelector()"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-account-group</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('customerportalIndex-choose') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider
                    v-if="locale === 'da'"
                    color="white"
                />
                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    link
                    :to="item.name"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
  
                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    link
                    to="contact"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-face-agent</v-icon>
                    </v-list-item-icon>
  
                    <v-list-item-content>
                        <v-list-item-title>Kontakt</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    link
                    to="logout"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
  
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('forcepasswordchange-logoutbuttontext') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app>
            <v-app-bar-nav-icon
                @click.stop="drawer = !drawer"
            >
                <v-icon>{{ drawericon }}</v-icon>
            </v-app-bar-nav-icon>
            <div
                class="text-center"
                style="width:100%;"
            >
                {{ getSelectedPNumberName + ' (' + getSelectedPNumber + ')' }}
            </div>
        </v-app-bar>

        <!-- Sizes your content based upon application components -->
        <v-main v-if="!cvrNumberPNumberLoading">
            <router-view />
        </v-main>
        <v-main v-else>
            <v-container
                fluid
                class="fill-height"
            >
                <v-row justify="center">
                    <v-col
                        cols="12"
                    >
                        <div class="d-flex justify-center">
                            <div
                                class="d-flex flex-column "
                            >
                                <v-progress-circular
                                    :size="70"
                                    :width="7"
                                    color="#000000"
                                    indeterminate
                                />
                                <div>{{ $t('customerportal-loading') }}</div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>

        <footer-bar />

        <v-dialog
            v-model="inactivityDialog"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="py-1">
                    {{ $t('inactivityWindow-message') }}
                </v-card-title>
  
                <v-card-text>
                    {{ $t('inactivityWindow-warning') }} {{ countdown }} {{ $t('inactivityWindow-seconds') }}
                </v-card-text>
  
                <v-card-actions>
                    <v-spacer />
  
                    <v-btn
                        :to="{ name: 'LogOut' }"
                    >
                        {{ $t('forcepasswordchange-logoutbuttontext') }}
                    </v-btn>
  
                    <v-btn
                        @click="resetInactivityCountdown"
                    >   
                        {{ $t('inactivityWindow-stay') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import FooterBar from '@/views/shared/Footer'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    components: {
        FooterBar
    },
    data() {
        return {
            cvrNumberPNumberLoading: false,
            drawer: !this.$vuetify.breakpoint.mobile,
            items: [               
                { title: this.$t('menu-user'), icon: 'mdi-account', name: 'myprofile' }
            ],
            RestrictedItems: [
                { title: this.$t('orderitemproductperiodvehiclelist'), icon: 'mdi-car', name: 'registrationnumbers' },
                { title: this.$t('menu-entrances'), icon: 'mdi-boom-gate-up', name: 'entrancelist' },
                { title: this.$t('orders'), icon: 'mdi-basket', name: 'orderlist'}                               
            ],
            mini: true,

            //Dialog: inactivity
            inactivityDialog: false,
            inactivityTimer: null,
            inactivityCountdown: null,
            countdown: 0,

            selectedCvrNumber: null,
            selectedCvrName: null,
            selectedPNumber: null,
            selectedPNumberName: null,
            pNumberList: []
        }
    },
    computed: {
        locale(){
            return process.env.VUE_APP_I18N_LOCALE
        },
        color(){
            return process.env.VUE_APP_COMPANY_COLOR
        },
        darkMode(){
            return process.env.VUE_APP_COMPANY_DARK === '1'
        },
        img(){
            return process.env.VUE_APP_COMPANY_LOGO
        },
        allowSubscription(){
            return this.GetSubscriptionEnabled
        },
        hideRegistrationNumberList(){
            return process.env.VUE_APP_HIDE_REGISTRATIONLIST === 'true'
        },
        drawericon() {
            if(this.$vuetify.breakpoint.mobile){
                return 'mdi-menu'
            }

            return this.drawer ? 'mdi-backburger' : 'mdi-forwardburger'
        },
        ...mapGetters({
            getSelectedPNumber: 'getSelectedPNumber',
            getSelectedPNumberName: 'getSelectedPNumberName',
            GetSubscriptionEnabled: 'GetSubscriptionEnabled'
        })
    },
    beforeMount(){
        if(this.hideRegistrationNumberList){
            this.RestrictedItems = this.RestrictedItems.filter(item => item.name !== 'registrationnumbers')
        }

        this.GetSystemSetting({type: 5}).then(result => {
            this.SetSubscriptionEnabled(result.setting.subscriptionAddonActive) 
            this.SetSubscriptionMode(result.setting.subscriptionMode)
             //I'm not sure if this is hacky or not. But the text doesn't change if we set it in the data, I assume because allowSubscription is false at the time of creation
            //  this.RestrictedItems.unshift({ title: this.allowSubscription ? this.$t('creditCardAndSubscription') : this.$t('creditCardList'), icon: 'mdi-credit-card-plus-outline', name: 'creditcardlist'})
        })
    },
    watch: {
        inactivityDialog(val) {
            if(val){
                this.setInactivityCountdown() 
            }
        },
        allowSubscription(){
            this.updateRestrictedItems()
        }
    },
    created(){       
        let intervalContainer = setInterval(() => {
            this.RefreshTokenInterval({
                refreshToken: localStorage.getItem('refreshtoken')
            })
        }, 840000)

        this.setIntervalContainer(intervalContainer)

        if(localStorage.getItem('selectedPNumber') !== null){
            this.setSelectedPNumber(localStorage.getItem('selectedPNumber'))
            this.setSelectedPNumberName(localStorage.getItem('selectedPNumberName'))
        }

        window.addEventListener('keyup', this.resetInactivityTimer)
        window.addEventListener('mousedown', this.resetInactivityTimer)  

        this.setInactivityTimer()

        this.cvrNumberPNumberLoading = true

        //FETCH THE CVR INFO FOR THE CURRENT LOGGED IN USER
        //AND
        //FETCH THE P-NUMBER LIST FOR THE LOGGED IN USER
        Promise.all([
            this.FetchUserCvrNumber(),
            this.FetchPNumberListByUser()
        ])
            .then(results => {
                //cvr info results
                this.selectedCvrNumber = results[0].cvrNumber
                this.selectedCvrName = results[0].name
                
                this.setSelectedCvrNumber(this.selectedCvrNumber)

                // this.pNumberList = results.length > 1 ? results[1].pNumberList : []

                // if(this.pNumberList.length > 0){
                //     this.setSelectedPNumber(this.pNumberList[0].pNumber)                     
                //     this.setSelectedPNumberName(this.pNumberList[0].name)       
                // }
                //GET OR CREATE THE CURRENT USER
                this.FetchUser({
                    pNumber: this.getSelectedPNumber
                })
                    .then(() => {
                        //SET THE RESTRICTED MENU ITEMS
                        this.items = this.items.concat(this.RestrictedItems)
                        //FETCH THE CUSTOMER REGISTRATION RELATION LIST
                        this.FetchCustomerRegistrationRelationList({pNumber: this.getSelectedPNumber, cvrNumber: this.getSelectedCvrNumber})
                            .then(result => {
                                if(result.customerRegistrationRelationList.length < 1 && this.$route.name === 'Welcome'){
                                    this.$router.push({ name: 'RegistrationNumbers' })
                                }
                                this.cvrNumberPNumberLoading = false                        
                            })
                    })
                this.selectedPNumber = this.getSelectedPNumber

               this.updateRestrictedItems()
            })        
    },
    methods: {
        setInactivityTimer(){
            this.inactivityTimer = setTimeout(() => {
                this.inactivityDialog = true                           
            }, 840000)            
        },
        resetInactivityTimer(){
            clearTimeout(this.inactivityTimer)
            this.setInactivityTimer()
        },
        updateRestrictedItems(){
            this.RestrictedItems = this.RestrictedItems.filter(item => item.name !== 'creditcardlist' && item.name !== 'creditcardandsubscription')
            
            this.RestrictedItems.unshift({
                title: this.allowSubscription ? this.$t('creditCardAndSubscription') : this.$t('creditCardList'),
                icon: 'mdi-credit-card-plus-outline',
                name: 'creditcardlist'
            })
        },
        setInactivityCountdown(){
            this.countdown = 10

            this.inactivityCountdown = setInterval(() => {
                if(this.countdown > 0){
                    this.countdown = this.countdown - 1                    
                }
                else {
                    clearInterval(this.inactivityCountdown)

                    window.removeEventListener('keyup', this.resetInactivityTimer)
                    window.removeEventListener('mousedown', this.resetInactivityTimer)

                    this.LogOut()
                    this.$router.push({ name: 'Login' })
                }                
            }, 1000)
        },
        resetInactivityCountdown(){
            clearInterval(this.inactivityCountdown)
            this.resetInactivityTimer()
            this.inactivityDialog = false
        },
        linkToPNumberSelector(){
            this.$router.push({ name: 'PNumberSelector' })
        },
        checkRestrictedMenuItem(){
            return this.RestrictedItems.filter(x => x.name.toLowerCase() === this.$route.name.toLowerCase()).length > 0
        },
        ...mapMutations({
            setIntervalContainer: 'setIntervalContainer',
            setSelectedCvrNumber: 'setSelectedCvrNumber',
            setSelectedPNumber: 'setSelectedPNumber',
            setSelectedPNumberName: 'setSelectedPNumberName',
            SetSubscriptionEnabled: 'SetSubscriptionEnabled',
            SetSubscriptionMode: 'SetSubscriptionMode'
        }),
        ...mapActions({
            FetchUser: 'FetchUser',
            FetchUserCvrNumber: 'FetchUserCvrNumber',
            FetchPNumberListByUser: 'FetchPNumberListByUser',
            RefreshTokenInterval: 'RefreshTokenInterval',
            LogOut: 'LogOut',
            FetchCustomerRegistrationRelationList: 'FetchCustomerRegistrationRelationList',
            GetSystemSetting: 'GetSystemSetting'
        })
        
    }
}
</script>
